.grid{
    display: grid;
    grid-template-columns: var(--grid);
    grid-row-gap: var(--v-gutter);
    grid-column-gap: var(--gutter) ;
}

.gridLeft{
    grid-column: 1/7;
}
.gridRight{
    grid-column: 7/13;
}
.gridInner{
    padding: 16px 32px 16px 0 ;
}
.borderRight{
    border-right: 1px solid var(--grey200);
}

.card{
    border: 1px solid var(--grey200);
    margin-top: 50px;
}
.cardTitle{
    padding: 10px 20px;
    font-size: 20px;
    border-bottom: 1px solid var(--grey200);
}

.tableHeader{
    display: inline-flex;
    width: 100%;
    font-size: 14px;
    color: var(--grey600);
    padding: 10px 0;
}
.tableRow{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px 0;
}
.tableHeaderItem, .tableRowItem{
    flex: 1;
}
.tableHeaderAction,.tableRowAction{
    width: 75px;
    margin-right: 20px;
    text-align: center;
}