@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
    --tableTitleFontSize: 14px;
    --font_Title: 20px;
    --font_Desc: 14px;

      --grey800: #354147;
    --grey600: #697175;
    --grey400: #8E9599;
    --grey200: #B4BABD;
    --grey100: #D6DBDD;
    --grey50: #EDF1F2;
    --primaryGreen: #2f7d67;
    --red: #e74c3c;

    --grid: repeat(12,minmax(10px,1fr));
    --v-gutter: 5.55556rem;
    --gutter: 1.66667rem;
    --h-spacing: 1.66667rem;
    --section-v-spacing: 5.55556rem;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p{
    margin: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
    text-transform: none !important;
}

/* Global table */

.tableContainer {
    margin-top: 10px;
}
.tableHead {
    background-color: #f3f4f6;
}
.theadCell {
    font-size: var(--tableTitleFontSize);
}
.tableRow {
    border-bottom: 1px solid #e6e8f0;
    cursor: pointer;
}
.tableCell {
    font-size: 1rem;
}

/* Page */
.backContainer {
    display: flex;
    cursor: pointer;
    align-items: center;
    width: fit-content;
    padding: 20px 0 0 20px;
}
.backContainer img {
    height: 1.25rem;
    width: 1.25rem;
}
.backContainer button {
    font-size: 0.875rem;
    font-weight: bold;
}

.modal-info-wrapper{
    display: inline-flex;
    width: 100%;
    margin-bottom: 20px;
}
.modal-info{
    display: flex;
    flex-direction: column;
    flex: 1;
}
.modal-info div:first-child{
    font-size: 12px;
    padding-bottom: 5px;
    color: var(--grey600);
}
.modal-info div:last-child{
    font-size: 16px;
    color: var(--grey800);
}

.modal-input-wrapper{
    margin-bottom: 20px;
}
.modal-input-wrapper.full > div{width: 100%;}

/* Modal Style */
.uM_title{
    padding: 20px 20px 10px 20px;
    font-size: var(--font_Title);
}
.uM_desc{
    padding: 10px 20px 20px 20px;
    font-size: var(--font_Desc);
    color: var(--grey800);
}
.uM_body{
    padding: 10px 20px 20px 20px;
    flex: 1;
    max-height: 500px;
    overflow: auto;
}
.uM_action{
    padding: 20px;
}
.uM_action button{
    margin-left: 20px;
}
