@import '../../utils/cssConstants.scss';

.parentContainer {
    background-color: #f2f6fc;
    width: 111.11%;
    height: 111.11vh;
    position: fixed;
    top: 0;
    left: -5.55%;
    //background-image: url('/image/login_background.svg');
    //background-repeat: no-repeat;
    //background-size: cover;
    //background-position: center;
    .dialogContainer {
        background-color: white;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 2.5rem;
        border-radius: 20px;
        width: 25rem;
        .logo {
            width: 11.25rem;
            margin: auto;
            margin-bottom: 2.5rem;
        }
        .dialogContentContainer {
            display: flex;
            flex-direction: column;
            .header {
                font-weight: bold;
                font-size: 1.25rem;
                margin-bottom: 1.25rem;
            }
            .form {
                display: flex;
                flex-direction: column;

                .textField {
                    margin-bottom: 1.875rem;

                    .input {
                        font-size: 1rem;
                    }
                }

                .loginButton {
                    padding: 0.625rem 6.25rem;
                    background-color: $themeGreen;
                    color: white;
                    font-weight: bold;
                    font-size: 1rem;
                    appearance: none;
                    border: none;
                    cursor: pointer;
                    border-radius: 8px;
                }
            }
        }
    }
}

@media (max-width: 768px) { // Adjust the max-width value according to your needs for mobile breakpoint
    .parentContainer {
        .dialogContainer {
            background-color: #f2f6fc;
        }
    }
}
