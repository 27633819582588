body {
    zoom: 90%;
    background-color: #f2f6fc;
    font-family: Calibri, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
    color: rgba(0,0,0,.87);
}
.parentContainer {
    display: flex;
    .navbarContainer {
        width: 275px;
        height: 100vh;
    }
}

.editable-cell:hover .edit-button {
    opacity: 1;
}
