.uploadModalWrapper{
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 99;
}
.uM_backdrop{
    position: fixed;
    width: 111.11%;
    height: 111.11vh;
    top: 0;
    left: -5.55%;
    background-color: rgba(0, 0, 0, .25);
}
.uM_Inner{
    background-color: white;
    width: 600px;
    border-radius: 8px;
    z-index: 9;
    /* min-height: 400px; */
    display: flex;
    flex-direction: column;
}
