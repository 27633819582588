$themeBlack: #354147;
$themeGrey: #d6dbdd;
$themeTextGrey : #697175;
$themeGreen: #2f7d67;
$themePurple: #5048e5;
$themeRed: #e53e3e;

@mixin tag {
    color: #edf1f2;
    padding: 0.3125rem;
    border-radius: 0.3125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.85rem;
    width: fit-content;
}
