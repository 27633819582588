.parentContainer{
    .allRecordContainer{
        padding: 0 20px;
        .header {
            margin-top: 3.75rem;
            font-weight: bold;
            font-size: 1.5rem;
            width: 100%;
            display: inline-flex;
            justify-content: space-between;
        }
}
}