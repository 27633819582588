.ctn{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    .title{
        font-size: 20px;
        color: var(--grey600)
    }
    .subtitle{
        color: var(--grey400)
    }
}