@import '../../../../utils/cssConstants';

.parentContainer {
    height: 100%;
    .allRecordContainer {
        padding: 0 1.57rem;
        .header {
            margin-top: 3.75rem;
            font-weight: bold;
            font-size: 1.5rem;
        }
        .searchContainer {
            padding: 1.875rem 1.25rem;
            border-radius: 0.625rem;
            box-shadow: 0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1);
            .searchInput {
                width: 50%;
                font-size: 1rem;
            }
            .searchIcon {
                margin-right: 0.625rem;
            }
        }
        .statusNavbar {
            display: flex;
            margin-top: 1rem;
            .statusNavItem {
                color: #8e9599;
                cursor: pointer;
                margin-right: 2rem;
                font-size: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .active {
                color: $themeGreen;
                font-size: 20px;
                position: relative;
                &::after {
                    content: '';
                    position: absolute;
                    background-color: $themeGreen;
                    height: 5px;
                    left: 0;
                    bottom: -10px;
                    margin: 0 5px;
                    width: 80%;
                }
            }
        }
        .tableContainer {
            margin-top: 2rem;
            .tableHead {
                background-color: #f3f4f6;
                .theadCell {
                    font-size: var(--tableTitleFontSize);
                }
            }
            .tableRow {
                border-bottom: 1px solid #e6e8f0;
                cursor: pointer;
                .tableCell {
                    font-size: 1rem;
                }
                .copyButton {
                    color: black;
                    background-color: transparent;
                    .copyIcon {
                        width: 1rem;
                        height: 1rem;
                    }
                    &:hover {
                        background-color: rgba($color: #b4babd, $alpha: 0.5);
                    }
                }
            }
        }
    }
    .detailRecordContainer {
        height: 100%;
        //background-color: #edf1f2;
        .backContainer {
            display: flex;
            cursor: pointer;
            width: fit-content;
            margin-bottom: 1.5rem;
            img {
                height: 1.25rem;
                width: 1.25rem;
                margin-right: 0.625rem;
            }
            div {
                font-size: 0.875rem;
                font-weight: bold;
            }
        }
        .headingContainer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .leftHeadingContainer {
                display: flex;
                align-items: center;
                .intake {
                    color: $themeTextGrey;
                    font-weight: bold;
                    font-size: 36px;
                }
                .editButton {
                    margin-left: 1rem;
                }
            }
            .rightHeadingContainer {
                display: flex;
                justify-content: space-between;
                .courseName {
                    color: $themeTextGrey;
                    font-weight: bold;
                    font-size: 36px;
                }
                .date {
                    color: $themeTextGrey;
                    font-size: 20px;
                }
            }
        }
        .buttonContainer {
            display: inline-flex;
            justify-content: space-between;
            margin-top: 1rem;
            height: 3rem;
            >div{
                margin-right: 20px;
            }
        }
        .breakLine {
            height: 1px;
            width: 100%;
            background-color: #b4babd;
            margin: 2rem 0;
        }
        .infoNavbar {
            margin-top: 2rem;
            display: flex;
            position: relative;
        }

        .infoNavItem {
            color: #1f1f1f;
            cursor: pointer;
            margin-right: 2rem;
            font-size: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            padding: 10px;
            transition: background-color 0.5s ease;
            border-radius: 15px;
        }

        .infoNavItem:hover {
            background-color: rgba($themeGreen, 0.05);
        }

        .active {
            background-color: rgba(46, 125, 50, 0.08);
            font-weight: bolder;
        }
        .tableContainer {
            margin-top: 2rem;
            .tableHead {
                background-color: #f3f4f6;
                .theadCell {
                    font-size: var(--tableTitleFontSize)
                }
            }
            .tableRow {
                border-bottom: 1px solid #e6e8f0;
                cursor: pointer;
                background-color: white;
                .tableCell {
                    font-size: 1rem;
                }
                .copyButton {
                    color: black;
                    background-color: transparent;
                    .copyIcon {
                        width: 1rem;
                        height: 1rem;
                    }
                    &:hover {
                        background-color: rgba($color: #b4babd, $alpha: 0.5);
                    }
                }
            }
            .tableFooter {
                background-color: white;
            }
        }

        .courseContainer {
            display: flex;
            margin: 2rem 0;
            background-color: #f8fefc;
            padding: 1rem 1.5rem;

            .imageContainer {
                flex: 1;
                img {
                    aspect-ratio: 1;
                    width: 100%;
                }
            }
            .courseDetailContainer {
                flex: 5;
                margin-left: 1rem;
                .courseName {
                    color: #354147;
                    font-size: 18px;
                    font-weight: bold;
                    margin-bottom: 1rem;
                }
            }
        }
    }
    .greenTag {
        @include tag();
        background-color: $themeGreen;
        cursor: pointer;
    }
    .darkgreyTag {
        @include tag();
        background-color: $themeTextGrey;
        cursor: pointer;
    }
    .lightgreyTag {
        @include tag();
        background-color: #b4babd;
    }
    .warmTag {
        @include tag();
        background-color: #eb8322;
    }
}

.copyIntakeDialogContainer {
    padding: 1rem 2rem;
    .title {
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 1rem;
    }
    .content {
        margin-bottom: 1rem;
    }
    .buttonContainer {
        display: flex;
        justify-content: flex-end;
        .cancelButton {
            color: #8e9599;
            margin-right: 1rem;
        }
        .copyButton {
            color: white;
            background-color: $themePurple;
        }
    }
}

.intakeClassContainer{
    margin-top: 30px;
}
.intakeClassWrapper{
    padding: 20px;
    margin-bottom: 10px;
    background-color: white;
    .intakeClassHeader{
        display: inline-flex;
        width: 100%;
        .intakeClassName{
            flex: 1;
            font-size: 20px;
    font-weight: bold;
    padding-bottom: 5px;
        }
    }
    .intakeClassContent{

    }
    .intakeClassAction{

    }
}

.chipsWrapper{
    display: inline-flex;
    flex-wrap: wrap;
    margin-top: 10px;
    .chip{
        margin-right: 10px;
        margin-bottom: 10px;
        border: 1px solid var(--grey100);
        border-radius: 4px;
        padding: 4px 8px;
    }
}
