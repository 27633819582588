@import '../../../../utils/cssConstants.scss';

.infoNavbar {
  margin-top: 2rem;
  display: flex;
  position: relative;
}

.infoNavItem {
  color: #1f1f1f;
  cursor: pointer;
  margin-right: 2rem;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 10px;
  transition: background-color 0.5s ease;
  border-radius: 15px;
}

.infoNavItem:hover {
  background-color: rgba($themeGreen, 0.05);
}

.active {
  background-color: rgba(46, 125, 50, 0.08);
  font-weight: bolder;
}
