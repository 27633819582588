@import '../../utils/cssConstants.scss';

.parentContainer {
    height: 98%;
    width: calc(275px - 20px);
    position: fixed;
    top: 10px;
    left: 10px;
    .logoContainer {
        display: flex;
        align-items: center;
        padding: 1rem;
        margin-bottom: 1.57rem;
    }

    .logo {
        flex-shrink: 0;
        width: auto;
        height: auto;
    }

    .textContainer {
        margin-left: 0.5rem; /* Adjust the spacing between logo and text as needed */
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .title {
        font-size: 1.2rem; /* Adjust the font size for the title as needed */
        font-weight: 500; /* Make the title bolder */
        color: #00702c; /* Change the title color as needed */
    }

    .version {
        font-size: 0.875rem; /* Adjust the font size for the version as needed */
        color: #000000; /* Dim the version color if needed */
    }
    .buttonWrapper{
        margin: 2rem 1rem;
        padding: 0.625rem;
        border-radius: 0.625rem;
        .cusBtn{
            color: $themeGrey;
            font-weight: bold;
            font-size: 0.875rem;

        }
    }
    .masterNav {
        display: flex;
        flex-direction: column;
        margin: 0 1rem;
        padding: 0.625rem;
        border-radius: 0.625rem;
        text-decoration: none;
        cursor: pointer;

        .masterTitle {
            display: flex;
            padding-bottom: 12px;
        }
        img {
            width: 1.25rem;
            height: 1.25rem;
            margin-right: 0.625rem;
            filter: invert(85%) sepia(6%) saturate(202%) hue-rotate(155deg) brightness(89%) contrast(86%);
        }
        div {
            color: $themeGrey;
            font-weight: bold;
            font-size: 0.875rem;
        }
    }

    .link {
        display: flex;
        margin: 0 1rem;
        padding: 0.625rem;
        border-radius: 0.625rem;
        text-decoration: none;
        cursor: pointer;
        img {
            width: 1.25rem;
            height: 1.25rem;
            margin-right: 0.625rem;
            filter: invert(85%) sepia(6%) saturate(202%) hue-rotate(155deg) brightness(89%) contrast(86%);
        }
        div {
            color: #1f1f1f;
            font-size: 1rem;
        }
    }

    .active {
        background-color: rgba(46, 125, 50, 0.08);
        font-weight: bolder;
    }
}
