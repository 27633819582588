@import '../../utils/cssConstants.scss';

@mixin container() {
    display: flex;
    background-color: white;
    border-radius: 8px;
    padding: 1.5rem 2rem;
}

.parentContainer {
    .allRecordContainer {

        .header {
            margin-top: 3.75rem;
            font-weight: bold;
            font-size: 1.5rem;
        }

        .searchContainer {
            padding: 1.875rem 1.25rem;
            border-radius: 0.625rem;
            box-shadow: 0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1);

            .searchInput {
                width: 50%;
                font-size: 1rem;
            }

            .searchIcon {
                margin-right: 0.625rem;
            }
        }

        .tableContainer {
            margin-top: 2rem;

            .tableHead {
                background-color: #f3f4f6;

                .theadCell {
                    font-size: var(--tableTitleFontSize)
                }
            }

            .tableRow {
                border-bottom: 1px solid #e6e8f0;
                cursor: pointer;

                .tableCell {
                    font-size: 1rem;
                }
            }
        }
    }

    .detailRecordContainer {
        background-color: transparent;

        .header {
            font-size: 1.25rem;
            font-weight: bold;
            margin-top: 2.5rem;
            margin-bottom: 0.625rem;
        }

        .info {
            display: flex;
            flex-direction: column;
            margin-bottom: 1.25rem;

            .infoHeader {
                color: #6b7280;
                font-size: 0.75rem;
                margin-top: 1rem;
            }

            .infoContent {
                font-weight: bold;
                font-size: 1.125rem;
            }
        }

        .backContainer {
            display: flex;
            cursor: pointer;
            width: fit-content;

            img {
                height: 1.25rem;
                width: 1.25rem;
                margin-right: 0.625rem;
            }

            div {
                font-size: 0.875rem;
                font-weight: bold;
            }
        }

        .grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            // grid-template-rows: repeat(1, 1fr);
            column-gap: 1.25rem;

            .approveButton {
                font-size: 0.9375rem;
                color: $themePurple;
                border-color: $themePurple;
                padding-top: 0.625rem;
                padding-bottom: 0.625rem;
                border-radius: 8px;

                &:disabled {
                    color: rgba(55, 65, 81, 0.26);
                    border-color: rgba(55, 65, 81, 0.26);
                }
            }

            .enrollmentRecordSession {
                grid-column: 1 / 3;

                .enrollmentRecordContainer {
                    @include container();

                    .courseDetailContainer {
                        border-right: 1px solid $themeGrey;
                        flex: 3;
                    }

                    .studentDetailContainer {
                        flex: 1;
                        margin-left: 2.5rem;
                    }
                }
            }

            .qualificationSession {
                grid-column: 1 / 3;

                .qualificationContainer {
                    @include container();
                    padding-top: 0.5rem;

                    .fileContainer {
                        flex: 1;
                    }

                    .approveContainer {
                        flex: 1;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;

                        .box {
                            width: 100%;
                            margin-bottom: 1.875rem;
                        }

                        .academicLevelSelectContainer {
                            width: 200px;
                            margin-right: 2rem;
                        }
                    }
                }
            }

            .paymentSession {
                grid-column: 1 / 3;

                .paymentContainer {
                    @include container();
                    padding-top: 0.5rem;

                    .fileContainer {
                        flex: 1;
                        border-right: 1px solid $themeGrey;

                        .uploadTime {
                            margin-top: 1rem;
                            font-weight: bold;
                        }

                        .imageLinkContainer {
                            margin-top: 0;
                        }
                    }

                    .paymentRecordContainer {
                        flex: 1;
                    }
                }

                .paymentObjectContainer {
                    border-radius: 8px;
                    border: solid 1px $themeGrey;
                    margin-top: 1rem;
                    margin-left: 2rem;
                    padding: 1rem 2rem;

                    .instalmentInfoContainer {
                        display: grid;
                        grid-template-columns: repeat(5, 1fr);
                        border-bottom: solid 1px $themeGrey;
                        margin-bottom: 1rem;
                        padding-bottom: 1rem;

                        .instalmentAmount {
                            grid-column: 1 / 5;
                            font-size: 20px;
                            font-weight: bold;
                        }

                        .tag {
                            grid-column: 5 / 6;
                            margin-left: auto;
                        }

                        .dueDate {
                            grid-column: 1 / 6;
                            color: $themeTextGrey;
                        }
                    }

                    .optionContainer {
                        .inputRow {
                            display: flex;
                            column-gap: 1rem;

                            .paymentAmount {
                                flex: 1;
                            }

                            .transferDate {
                                flex: 1;
                            }
                        }

                        .buttonContainer {
                            display: flex;
                            justify-content: flex-end;
                            margin-top: 1rem;
                        }
                    }
                }

                .paidPaymentObjectContainer {
                    background-color: #edf1f2;
                }
            }

            .supportingDocumentSession {
                grid-column: 1 / 3;

                .supportingDocumentContainer {
                    @include container();
                    display: block;
                    padding: 3rem;

                    .uploadTime {
                        font-weight: bold;
                    }

                    .imageLinkContainer {
                        margin-top: 0;
                        margin-bottom: 1rem;
                    }
                }
            }
        }
    }

    .imageLinkContainer {
        display: flex;
        margin-top: 1rem;

        img {
            width: 20px;
        }

        .imageName {
            text-decoration: underline;
            font-size: 16px;
            font-weight: bold;
            margin-left: 1rem;
        }

        .activeName {
            cursor: pointer;
            color: #10b981;
            overflow-wrap: break-word;
            max-width: 400px;
        }

        .inactiveName {
            color: $themeTextGrey;
            overflow-wrap: break-word;
            max-width: 400px;
        }
    }

    .greenTag {
        @include tag();
        background-color: $themeGreen;
    }

    .darkgreyTag {
        @include tag();
        background-color: $themeTextGrey;
    }
    .redTag{
        @include tag();
        background-color: $themeRed;
    }

    .lightgreyTag {
        @include tag();
        background-color: #b4babd;
    }

    .warmTag {
        @include tag();
        background-color: #eb8322;
    }
}

.imageDialog {
    padding: 1rem;
}

.studentInfoLabel{
    font-size: 14px;
}
