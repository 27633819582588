.topbarContainer {
  width: calc(100% - 300px);
  height: 50px;
  top: 15px;
  left: 285px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
}
