.backgroundMask {
    width: 111.11%;
    height: 111.11vh;
    position: fixed;
    top: 0;
    left: -5.55%;
    background-color: rgba($color: #ffffff, $alpha: 0.7);
    z-index: 3000;
    .loading {
        position: relative;
        width: 3.125rem;
        height: 3.125rem;
        top: 50%;
        left: 50%;
        z-index: 3000;
    }
}
