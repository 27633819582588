@import '../../../utils/cssConstants.scss';

input[type='number'] {
    appearance: none;
    outline: none;
    border: none;
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

@mixin whiteContainer {
    background-color: white;
    padding: 2rem;
    margin-top: 2rem;
    .title {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 2rem;
    }
}

@mixin headingSession {
    background-color: #edf1f2;
    min-height: 100vh;
    //   overflow-y: auto;
    .headingContainer {
        display: flex;
        background-color: white;
        padding: 2rem;
        align-items: center;

        .backToCourseListButton {
            display: flex;
            font-size: 20px;
            font-weight: bold;
            cursor: pointer;
            margin-right: 3rem;
            .backArrow {
                width: 2rem;
                margin-right: 1rem;
            }
        }
        .header {
            font-size: 32px;
            font-weight: bold;
        }
    }
}

.newCourseParentContainer {
    @include headingSession();
    .mainNewCourseContainer {
        padding: 50px 0;
        width: 1190px;
        margin: 0 auto;
        .stepIndicatorContainer {
            display: flex;
            .stepIndicator {
                display: flex;
                flex-direction: column;
                .circle {
                    background-color: #c4c4c4;
                    border-radius: 50%;
                    width: 3rem;
                    height: 3rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: white;
                    font-size: 24px;
                    position: relative;
                    margin-right: 10rem;
                }
                .active {
                    background-color: $themePurple;
                }
                &:not(:last-child) {
                    .circle::after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 100%;
                        margin-left: 2rem;
                        height: 1px;
                        width: 6rem;
                        background-color: #b4babd;
                    }
                }
            }
        }
        .buttonContainer {
            display: flex;
            justify-content: flex-end;
            margin-top: 3rem;
            .backButton {
                margin-right: 3rem;
            }
        }
        .breakLine {
            height: 1px;
            width: 90%;
            background-color: #b4babd;
            margin: 2rem 0;
        }
        .deatilStepContainer {
            .courseDetailContainer {
                @include whiteContainer();
                .categorySelect {
                    width: 30%;
                }
                .courseName,
                .description {
                    margin-top: 2rem;
                    width: 80%;
                }
                .inputRow {
                    display: flex;
                    margin-bottom: 3rem;
                    .startDate,
                    .endDate,
                    .cutOffDate,
                    .academicLevel,
                    .price,
                    .discountedPrice,
                    .tenor,
                    .monthlyRepayment {
                        width: 30%;
                        margin-right: 3rem;
                    }
                }
                .scheduleDescription {
                    width: 80%;
                }
            }
        }
        .bannerStepContainer {
            .uploadImageContainer {
                @include whiteContainer();
                .buttonContainer {
                    display: flex;
                    justify-content: flex-start;
                    button {
                        margin-right: 3rem;
                    }

                    .uploadLabel {
                        margin-right: 3rem;
                        cursor: pointer;
                        border: solid 1px $themePurple;
                        color: $themePurple;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 4px;
                        padding: 5px 15px;
                    }
                    .inputFile {
                        opacity: 0;
                        position: absolute;
                        z-index: -1;
                    }
                }
                .videoUrl {
                    width: 100%;
                }
            }
            .bodyDescriptionContainer {
                @include whiteContainer();
                .editorWrapper {
                    border: 1px solid grey;
                    width: 80%;
                }
                .editor {
                    height: 20rem;
                    padding: 0 1rem;
                }
                .certName,
                .certDescription {
                    width: 100%;
                    margin-bottom: 3rem;
                }
            }
        }
        .tutorStepContainer {
            .tutorContainer {
                @include whiteContainer();
                .title {
                    margin-bottom: 2rem;
                }
                .breakLine {
                    height: 1px;
                    width: 90%;
                    background-color: #b4babd;
                    margin: 0rem;
                    margin-bottom: 2rem;
                }
            }
        }
        .scheduleStepContainer {
            .headerContainer {
                @include whiteContainer();
                display: flex;
                align-items: center;
                .title {
                    display: flex;
                    margin-bottom: 0;
                }
                .addWeekButton {
                    margin-left: auto;
                    height: 3rem;
                }
            }
            .weekSession {
                display: flex;
                .totalWeekContainer {
                    @include whiteContainer();
                    flex: 1;
                    padding: 1rem;
                    margin-right: 1.5rem;
                    height: fit-content;
                    .title {
                        margin-bottom: 0;
                    }
                    .breakLine {
                        height: 1px;
                        width: 100%;
                        background-color: #b4babd;
                        margin: 1rem 0;
                    }
                    .weekListItem {
                        padding: 0.5rem 1rem;
                        border-radius: 8px;
                        cursor: pointer;
                        display: flex;
                        .remove {
                            margin-left: auto;
                            display: none;
                        }
                        &:hover {
                            .remove {
                                display: flex;
                            }
                        }
                    }
                    .active {
                        background-color: rgba(#10b981, 8%);
                    }
                }
                .weekContainer {
                    @include whiteContainer();
                    flex: 4;
                    .className,
                    .classDescription {
                        width: 100%;
                        margin-bottom: 3rem;
                    }
                    .inputRow {
                        display: flex;
                        justify-content: space-between;
                        .startDate,
                        .endDate {
                            width: 45%;
                        }
                    }
                }
            }
        }
        .formStepContainer {
            .headerContainer {
                @include whiteContainer();
                display: flex;
                align-items: center;
                .title {
                    display: flex;
                    margin-bottom: 0;
                }
                .addButton {
                    margin-left: auto;
                    height: 3rem;
                }
            }
            .documentContainer {
                @include whiteContainer();
                flex: 4;
                .documentName {
                    text-decoration: underline;
                    color: $themeGreen;
                    margin-bottom: 3rem;
                    font-weight: bold;
                }
                .formContainer{
                    display: inline-flex;
                    width: 100%;
                    gap: 2rem;
                    .formTextfield{
                        width: 50%;
                    }
                    .formAction{
                        // height: 3rem;
                    }
                }
                .documentDescription {
                    width: 100%;
                    margin-bottom: 3rem;
                }
                .uploadLabel {
                    cursor: pointer;
                    background-color: #5048e5;
                    color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 4px;
                    padding: 10px 15px;
                }
                .disabledLabel {
                    background-color: grey;
                    color: white;
                    cursor: unset;
                    border: none;
                    &:hover {
                        background-color: grey;
                    }
                }
                .inputFile {
                    opacity: 0;
                    position: absolute;
                    z-index: -1;
                }
            }
        }
        .createInakeContainer {
            .infoText {
                font-size: 32px;
                font-weight: bold;
                margin-top: 8rem;
            }
        }
        .intakeDeatilStepContainer {
            .intakeDetailContainer {
                @include whiteContainer();
                .inputRow {
                    display: flex;
                    margin-bottom: 3rem;
                    .intake,
                    .quota,
                    .startDate,
                    .endDate,
                    .cutOffDate,
                    .academicLevel,
                    .price,
                    .discountedPrice,
                    .tenor,
                    .monthlyRepayment {
                        width: 30%;
                        margin-right: 3rem;
                    }
                }
                .scheduleDescription {
                    width: 80%;
                }
            }
        }
    }
}
